import { cn } from '#app/utils/misc.js'

export const Image = ({
	className,
	...props
}: React.DetailedHTMLProps<
	React.ImgHTMLAttributes<HTMLImageElement>,
	HTMLImageElement
>) => {
	return (
		// eslint-disable-next-line jsx-a11y/alt-text
		<img
			className={cn(
				'mx-auto h-auto w-full object-contain md:h-[600px]',
				className,
			)}
			{...props}
		/>
	)
}
